/* website: 2442-spinellimazda2
 * created at 2021-01-12 16:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/mazda.scss";
@import "../utils/icons.scss";

.widget-sr{
    @include clearfix;
    width: 100%;
    &.dealer__mazda-spinelli-lachine{
        .VehicleDisclaimersContent p{
            font-size: 11px;
            line-height: 12px;
        }
        .listing-used-filters-head .listing-used-filters-head-button p a{
            font-size: 12px;
        }
        .listing-tile .listing-tile-drivePowerTrains p{
            font-size: 11px;
        }
        .listing-tile .listing-tile-image .car-info{
            position: relative;
        }
        .listing-tile .listing-tile-image{
            display:flex;
            flex-direction: column-reverse;
        }
        /** fix place tradein at top of listing **/
        .listing-used-car-search>div:last-child{
            display: flex;
            flex-direction: column;
            #instant-estimate-bar-wrapper{
                order: 0;
                margin-top: 0px !important;
            }
            .listing-tiles{
                order: 1;
            }
            .listing-used-button-loading.sr-button-1{
                order: 2;
            }
        }
            /** fix AV widget padding on V2 VLP **/
        .listing-used-car-search{
            .av_widget_root{
                margin: 0 0 20px 0 !important;
            }
        }
    }
}